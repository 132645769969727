<template>
  <div id="article-card"
       class="bg-white rounded-lg p-4 sm:bg-pink sm:flex sm:gap-x-10 sm:items-start sm:mx-auto"
       :class="{'sm:flex-row-reverse': reverse}"
       style="max-width: 1800px"
  >
    <img v-if="articleData.articleImageUrl" :src="articleData.articleImageUrl" :alt="articleData.articleName"
          class="object-cover rounded-lg h-40 mt-4 w-full object-center hidden sm:block sm:w-2/3 sm:h-80 "/>
    <div class="sm:flex sm:flex-col sm:w-1/3 sm:h-80 sm:mt-4 sm:border-black sm:border-solid sm:border-t
                sm:border-b sm:py-8 sm:pr-8 sm:justify-between"
         :class="{'sm:border-r sm:pr-8': !reverse, 'sm:border-l sm:pl-8': reverse}"
    >
      <div class="flex items-center">
        <img  :src="articleData.ownerPhotoUrl" alt="Article Poster Photo" class="w-12 h-12 object-cover rounded-full"/>
        <div class="pl-2">
          <p class="text-black text-lg font-roboto font-bold"> {{articleData.companyName}} </p>
          <p class="font-robot text-xs"> {{createdDate}} </p>
        </div>
      </div>
      <img v-if="articleData.articleImageUrl" :src="articleData.articleImageUrl" :alt="articleData.articleName"
            class="object-cover rounded-lg h-40 mt-4 w-full object-center sm:hidden"/>
      <p class="font-montserrat text-2xl text-black py-3 sm:order-2"> {{articleData.articleName}} </p>
      <p v-if="articleData.shortDescription" class="font-roboto text-base leading-none max-h-14 mb-4
               sm:order-3 overflow-ellipsis line-clamp">
        {{ articleData.shortDescription }}
      </p>
      <div class="flex justify-between items-center sm:order-3">
        <div class="flex gap-2">
          <div v-for="(tag, index) in articleData.tags" :key="index"
              class="bg-black text-white px-4 py-0.5 rounded-md flex justify-center items-center text-xs">
            {{tag}}
          </div>
        </div>
        <div class="mr-4 arrow-wrapper" @click="goTo(articleData.articleName)">
          <div class="arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articleData: Object,
    reverse: Boolean,
  },
  computed: {
    createdDate() {
      // eslint-disable-next-line dot-notation
      const currentDate = new Date(this.articleData.createdDate['_seconds'] * 1000);
      return `${currentDate.getDate()}.${currentDate.getMonth()}.${currentDate.getFullYear()}`;
    },
    ownerPhotoUrl() {
      return this.articleData.ownerPhotoUrl ? this.articleData.ownerPhotoUrl : "https://i.imgur.com/wcbCIc3.jpeg.jpg";
    },
  },
  methods: {
    goTo(articleName) {
      this.$router.push({ name: 'ArticleDetailPage', params: { articleName } });
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  background: #333333;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  bottom: 0%;
  left: 30%;

  &:before,
  &:after {
    content: "";
    background: #333333;
    position: absolute;
    height: 3px;
    width: 15px;
  }

  &:before {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
  }
}

.arrow-wrapper {
  cursor: pointer;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 32px;
    top:-16px;
    right: -50%;
    @media screen and (min-width: 480px) {
      height: 50px;
      width: 200%;
      top:-25px;
    }
  }
}

.line-clamp{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
