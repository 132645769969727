<template>
  <div id="articles-page"
       class="min-h-screen relative"
       :class="{'bg-black px-10 pb-20 sm:px-0 sm:bg-pink': !$route.params.articleName}"
  >
    <div v-if="!$route.params.articleName">
      <b-loading :is-full-page="false" :active.sync="pageIsLoading"/>
      <div class="hidden sm:flex w-screen h-80 bg-dark_red relative justify-center items-end">
        <p class="font-montserrat font-extrabold text-dark_red text-4-5xl bg-pink inline-block px-28 py-10
                  rounded-t-xl">
          {{$t("articles.pageTitle")}}
        </p>
      </div>
      <div class="text-white font-montserrat text-4xl font-extrabold pt-14 pb-8 sm:hidden">
        {{$t("articles.pageTitle")}}
      </div>
      <div class="flex flex-col gap-5 sm:bg-pink sm:px-40 sm:py-28">
        <ArticleCard v-for="(article, index) in articles" :key="index" :articleData="article"
        :reverse="index % 2 == 1"/>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import ArticleCard from "../components/articles/ArticleCard.vue";

export default {
  components: {
    ArticleCard,
  },
  data() {
    return {
      pageIsLoading: true,
      articles: [],
    };
  },
  created() {
    fetch('https://us-central1-eestec-backend.cloudfunctions.net/app/api/articles',
      { method: 'GET' }).then((response) => {
      response.json().then((parsedResponse) => {
        //sort the articles by the created date, descrescator
        parsedResponse.sort((a, b) => {
          if (a.CreatedDate < b.CreatedDate) {
            return -1;
          }
          return 1;
        });
        this.articles = parsedResponse;
      });
    }).finally(() => {
      this.pageIsLoading = false;
    });
  },
};
</script>
